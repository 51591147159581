import React, { Fragment } from 'react';
import { Button, Typography, Form, Divider, Space } from 'antd';
import { navigate } from 'gatsby';
import DefaultLayout from '../components/layouts/default.layout';
import { PlusOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../state/types';
import { updateEducation } from '../state/actions';
import { Navigation } from '../components/navigation';
import EducationTemplate from '../components/form/education';
import { Severity } from '@sentry/react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Title, Paragraph, Text } = Typography;
const { useState } = React;

const EducationPage: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const education = useSelector((state: IState) => state.education || []);
  const [showFillInAllFields, setshowFillInAllFields] = useState<boolean>(
    false
  );

  const handleNext = () => {
    form.submit();
    form
      .validateFields()
      .then(values => {
        setshowFillInAllFields(false);
        const formattedData = valuesToData(values.education);
        dispatch(updateEducation(formattedData));
        navigate(`/qualifications${window.location.search}`);
      })
      .catch(err => {
        setshowFillInAllFields(true);
        Sentry.captureException(
          'Failure when reading education (rb)',
          context => {
            context.setLevel(Severity.Fatal);
            context.setExtras({
              error: err,
              error_fields: err?.errorFields[0],
              error_name: err.name,
              error_message: err.message,
              stack_trace: err.stack
            });
            return context;
          }
        );
      });
  };

  const valuesToData = (values: any): IEducation[] =>
    values.map((exp: Record<string, unknown>) => ({
      university_institution: exp.university_institution,
      degree_speciality: exp.degree_speciality,
      fromYear: exp.fromYear,
      toYear: exp.toYear,
      description: exp.description
    }));

  const initialValues = () => {
    if (education.length === 0)
      return [
        {
          university_institution: null,
          degree_speciality: null,
          fromYear: null,
          toYear: null,
          description: null
        }
      ];

    return education.map((data: IEducation) => ({
      university_institution: data.university_institution,
      degree_speciality: data.degree_speciality,
      fromYear: data.fromYear ? data.fromYear : null,
      toYear: data.toYear ? data.toYear : null,
      description: data.description
    }));
  };

  return (
    <DefaultLayout>
      <div style={s.header}>
        <Title level={2}>Education</Title>
        <Paragraph>Tell us about your education.</Paragraph>
      </div>

      <Form
        form={form}
        initialValues={{ education: initialValues() }}
        layout="vertical">
        <Form.List name="education">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, i) => (
                <Fragment key={i}>
                  <EducationTemplate
                    field={field}
                    remove={() => remove(field.name)}
                  />

                  <Divider />
                </Fragment>
              ))}

              <Button type="dashed" block onClick={add}>
                <PlusOutlined /> Add Education
              </Button>
            </>
          )}
        </Form.List>
      </Form>

      <div style={s.header}>
        <Space direction="vertical">
          <p>Please provide at least 1 education</p>
          {showFillInAllFields && (
            <Text type="danger">
              {' '}
              <ExclamationCircleOutlined /> Fill in All The Fields! Please
              scroll up!
            </Text>
          )}

          <Navigation
            step={4}
            goBackHandler={() =>
              navigate(`/work-experience${window.location.search}`)
            }
            goNextHandler={handleNext}
          />
        </Space>
      </div>
    </DefaultLayout>
  );
};

export default EducationPage;

const s: Stylesheet = {
  header: {
    textAlign: 'center',
    marginTop: 50
  }
};
