import React, { Fragment } from 'react';
import { Button, Form, Input, Popconfirm, Select } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { EDUCATION_LEVELS } from '../../common/constants';
import moment from 'moment';

const { Option } = Select;
const { useState, useEffect } = React;

interface IProps {
  field: FormListFieldData;
  remove: () => void;
}
interface ICompareStartEnd {
  startYear: number;
  endYear: number;
}

const EducationTemplate: React.FC<IProps> = ({ field, remove }) => {
  const defaultDateComparison: ICompareStartEnd = {
    startYear: 0,
    // we set endYear to a large number so it will not error and error if there is no end date field
    endYear: 10000
  };
  const [dateComparison, setDateComparison] = useState<ICompareStartEnd>(
    defaultDateComparison
  );

  const requiredRule = (validationMessage: string) => [
    { required: true, message: validationMessage, whitespace: true }
  ];

  const checkDates = (dateComparison: ICompareStartEnd) => {
    const { startYear, endYear } = dateComparison;

    const checkInvalidDates = startYear > endYear;

    if (checkInvalidDates)
      return Promise.reject(
        new Error('The start date must be earlier than the end date!')
      );
    else {
      return Promise.resolve();
    }
  };

  const currentYear = parseInt(moment().format('YYYY'));
  const graduationYear = currentYear + 5;

  return (
    <Fragment key={field.key}>
      <Form.Item
        label="University / Institution / School Name"
        name={[field.name, 'university_institution']}
        rules={requiredRule('Please enter a school or institution')}>
        <Input placeholder="Example: Yishun Secondary School" />
      </Form.Item>

      <Form.Item
        name={[field.name, 'fromYear']}
        label="Starting Year"
        dependencies={[['education', field.fieldKey, 'toYear']]}
        rules={[
          {
            required: true,
            message: 'Please enter a starting year'
          },
          {
            validator: (_, value) =>
              checkDates({ ...dateComparison, startYear: value })
          }
        ]}>
        <Select
          onChange={value =>
            setDateComparison({
              ...dateComparison,
              startYear: parseInt(value.toString())
            })
          }
          placeholder="Starting year">
          {Array.from(Array(70).keys()).map((i: number) => {
            return (
              <Option
                id={`data-test-${field.key}-starting-year-${currentYear - i}`}
                value={(currentYear - i).toString()}>
                {currentYear - i}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name={[field.name, 'toYear']}
        label="Graduation Year"
        dependencies={[['education', field.fieldKey, 'fromYear']]}
        rules={[
          {
            required: true,
            message: 'Please enter a graduation year'
          },
          {
            validator: (_, value) =>
              checkDates({ ...dateComparison, endYear: value })
          }
        ]}>
        <Select
          onChange={value =>
            setDateComparison({
              ...dateComparison,
              endYear: parseInt(value.toString())
            })
          }
          placeholder="Graduation year">
          {Array.from(Array(70).keys()).map((i: number) => {
            if (graduationYear - i < dateComparison.startYear) return;
            return (
              <Option
                id={`data-test-${field.key}-graduation-year-${graduationYear - i}`}
                value={(graduationYear - i).toString()}>
                {graduationYear - i}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Degree or Speciality"
        name={[field.name, 'degree_speciality']}>
        <Input placeholder="Example: Nitec - Engineering" />
        {/* <Select
          options={EDUCATION_LEVELS}
          placeholder="Example: Business Administration"
        /> */}
      </Form.Item>

      {field.key >= 1 && (
        <Popconfirm
          title="Are you sure you want to remove this education?"
          onConfirm={remove}>
          <Button type="dashed" danger block>
            Remove
          </Button>
        </Popconfirm>
      )}
    </Fragment>
  );
};

export default EducationTemplate;

const s: Stylesheet = {
  inline: { display: 'inline-block', width: '100%' },
  w100: { width: '100%' }
};
